module.exports = [{
      plugin: require('C:/Users/CTS/Documents/sites/edvelaj.net/v4/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/CTS/Documents/sites/edvelaj.net/v4/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1100,"quality":90,"linkImagesToOriginal":true},
    },{
      plugin: require('C:/Users/CTS/Documents/sites/edvelaj.net/v4/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-45666519-2"},
    },{
      plugin: require('C:/Users/CTS/Documents/sites/edvelaj.net/v4/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
